<template>
  <b-modal
    id="finish-purchase"
    title="Resumen de compra"
    cancel-variant="outline-danger"
    ok-variant="success"
    ok-title="Continuar"
    hide-header-close
    hide-footer
  >
    <div class="cart-modal-body overflow-auto">
      <div class="cart-modal grid">
        <cart :products="products" />
      </div>
      <div>
        <order-info-online />
        <div class="pt-2 d-flex flex-column p-1 send-info">
          <span v-if="$route.meta.storeType !== 'book_store'">
            <div class="mb-1 send-info-text">Enviar a</div>
              <b-card
                border-variant="info"
                v-if="optionsLocal.customer"
                class="cursor-pointer send-info-card"
                @click="$bvModal.show('change-delivery-address')"
              >
                <strong
                  v-if="optionsLocal.customer.address"
                  class="send-info-text"
                >
                  {{ optionsLocal.customer.address.street }}
                  {{ optionsLocal.customer.address.ext_number }}, CP
                  {{ optionsLocal.customer.address.postal_code }},
                  {{ optionsLocal.customer.address.city }},
                  {{ optionsLocal.customer.address.state }},
                  {{ optionsLocal.customer.address.country }}
                </strong>
              </b-card>
          </span>
        </div>
      </div>

      <div class="buttons">
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          type="button"
          variant="outline-danger"
          @click="$bvModal.hide('finish-purchase')"
        >
          Cancelar
        </b-button>
        <b-button
          v-if="$route.meta.storeType !== 'book_store'"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class=""
          type="button"
          @click="choosePaymentMethod()"
          :disabled="cart.length === 0"
        >
          Continuar
        </b-button>
        <b-button
          v-if="$route.meta.storeType === 'book_store'"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="success"
          class="mr-2"
          type="button"
          @click="choosePaymentMethod()"
          :disabled="cart.length === 0"
        >
          Continuar
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import Ripple from "vue-ripple-directive";
import OrderInfoOnline from "@/views/pos/OrderInfoOnline.vue";
import Cart from "@/views/pos/Cart.vue";

export default {
  directives: {
    Ripple,
  },
  data() {
    return {
      optionsLocal: JSON.parse(localStorage.getItem("userData")),
    };
  },
  props: {
    products: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    Cart,
    OrderInfoOnline,
  },
  computed: {
    ...mapGetters("pos", ["cart"]),
  },
  methods: {
    choosePaymentMethod() {
      this.$bvModal.hide("cart-modal");
      this.$bvModal.show("select-payment-method");
    },
  },
};
</script>

<style lang="scss" scoped>
.cart-modal {
  overflow-y: auto;
  border-radius: 10px 10px 10px 10px;
}

.cart-modal-body {
  max-height: 88vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
}

@media (max-width: 768px) {
  .send-info-text {
    font-size: 0.9rem;
  }

  .send-info {
    padding: 5px !important;
  }

  .send-info-card {
    margin-bottom: 5px !important;
  }
}

.buttons {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 1rem;
}
</style>
